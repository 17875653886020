import './bootstrap'
// import _ from 'lodash'
// import Dropzone from 'dropzone'
import 'preline'
// import '@preline/file-upload'
import Trix from 'trix';  // Explicit import to prevent tree-shaking
window.Trix = Trix;
import 'trix/dist/trix.css';


// console.log(_, Dropzone) // Check if both libraries are correctly imported

// window._ = _
// window.Dropzone = Dropzone
// window.HSFileUpload = {
//     _: _,
//     Dropzone: Dropzone
// }

// window.HSFileUpload = HSFileUpload

// document.addEventListener('DOMContentLoaded', () => {
//     console.log('Lodash:', _, 'Dropzone:', Dropzone) // Debugging
//     console.log('HSFileUpload:', HSFileUpload)

//     // Delay to ensure `lodash` and `Dropzone` are fully loaded before Preline
//     setTimeout(() => {
//         window.HSStaticMethods?.autoInit()
//     }, 100)
// })

// Trix Editor Initialization
document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('trix-initialize', (event) => {
        const trixEditor = event.target; // The specific trix-editor that initialized
        const contentInput = document.querySelector('#content');

        if (trixEditor && contentInput) {
            trixEditor.editor.loadHTML(contentInput.value);
        }
    });
});

// Trix Attachment Handling
document.addEventListener('trix-attachment-add', function (event) {
    if (event.attachment.file) {
        uploadFile(event.attachment)
    }
})

function uploadFile (attachment) {
    // Create FormData for the file upload
    const formData = new FormData()
    formData.append('file', attachment.file)

    // AJAX file upload request
    fetch('/api/upload-file', {
        method: 'POST',
        body: formData,
        headers: {
            'X-CSRF-TOKEN': document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content')
        }
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                attachment.setAttributes({
                    url: data.url,
                    href: data.url
                })
            } else {
                console.error('File upload failed:', data.message)
            }
        })
        .catch(error => {
            console.error('Upload error:', error)
        })
}

function deleteImage (imagePath, onSuccess) {
    // Send a request to delete the image from storage
    fetch(`/api/delete-gallery-image`, {
        method: 'POST',
        body: JSON.stringify({ image: imagePath }),
        headers: {
            'X-CSRF-TOKEN': document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content'),
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Handle successful deletion
                if (typeof onSuccess === 'function') onSuccess()
            } else {
                console.error('Image deletion failed:', data.message)
            }
        })
        .catch(error => console.error('Delete error:', error))
}
